<!--
 * @作者: 范绪超
 * @创建时间: 2023-12-12 17:34:44
 * @修改时间: 2024-07-13 20:40:31
 * @版本: [1.0]
 * @版权: 国泰新点软件股份有限公司
 * @描述: 
-->
<script>
// label  图片名称  类型
export default [
  [
    "我的电脑",
    "MyComputer",
    "Folder",
    true,
    [
      ["3½ 软盘 (A:)", "FloppyDrive", "Folder", true],
      [
        "本地磁盘 (C:)",
        "Drive",
        "Folder",
        true,
        [
          ["我的文档", "MyDocuments", "Folder", true],
          ["我的图片", "Folder", "Folder", true],
        ],
      ],
      ["(D:)", "DiskDrive", "Folder", true],
      ["控制面板", "Folder", "Folder", true],
    ],
  ],
  ["Internet", "Internet", "Internet", true],
  ["记事本", "Notepad", "Notepad", true],
  ["画板", "Paint", "Paint", true],
  [
    "程序",
    "Programs",
    "Folder",
    true,
    [
      ["Internet", "Internet", "Internet", true],
      ["记事本", "Notepad", "Notepad", true],
      ["AOL", "AOL", "AOL", true],
      ["画板", "Paint", "Paint", true],
    ],
  ],
  ["回收站", "RecycleBin", "Folder", true],
  // [
  //   "文件夹",
  //   "Folder",
  //   "Folder",
  //   true,
  //   [
  //     [
  //       "游戏",
  //       "Folder",
  //       "Folder",
  //       true,
  //       [
  //         ["Example", "Internet", "Internet", true],
  //         ["OctoPet", "Internet", "Internet", true],
  //         [
  //           "深层文件夹",
  //           "Folder",
  //           "Folder",
  //           true,
  //           [["回收站", "RecycleBin", "Folder", true]],
  //         ],
  //       ],
  //     ],
  //     ["Internet Link", "Internet", "Internet", true],
  //   ],
  // ],
  ["ITaylorfan的博客", "Taylor", "Link",true,[],"https://itaylorfan.top:1314/"],
  ["线上静态资源", "Folder", "Link",true,[],"https://itaylorfan.top:8082/"],
  ["前端基础笔记", "Taylor2", "Link",true,[],"https://itaylorfan.top:520/"],   //http://itaylorfan.gitee.io/blog/ 
  ["音乐API", "Taylor3", "Link",true,[],"http://music.itaylorfan.top/"],
  ["ITaylorfan的工具箱", "img_tools", "Link",true,[],"https://itaylorfan.top:8081"],
];
</script>